export const SN_ORDER = 'order'
export const ORDER_CURRENT_UPDATED = SN_ORDER + '/CURRENT_UPDATED'
export const ORDER_OPEN_UPDATED = SN_ORDER + '/OPEN_UPDATED'
export const ORDER_INFO_UPDATED = SN_ORDER + '/INFO_UPDATED'
export const ORDER_PACKED_UPDATED = SN_ORDER + '/PACKED_UPDATED'
export const ORDER_COMPLETED_UPDATED = SN_ORDER + '/COMPLETED_UPDATED'
export const ORDER_SHIP_TO_STORE_INCOMING_UPDATED = SN_ORDER + '/SHIP_TO_STORE_INCOMING_UPDATED'
export const ORDER_SHIP_TO_STORE_RDYFORPCKUP_UPDATED = SN_ORDER + '/SHIP_TO_STORE_RDYFORPCKUP_UPDATED'
export const ORDER_SHIP_TO_STORE_COMPLETED_UPDATED = SN_ORDER + '/SHIP_TO_STORE_COMPLETED_UPDATED'
export const ORDER_ITEM_REJECTION_HISTORY_UPDATED = SN_ORDER + '/ITEM_REJECTION_HISTORY_UPDATED'
