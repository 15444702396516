export default {
    "APP_ORDERS_VIEW": "",
    "APP_CATALOG_VIEW": "",
    "APP_ORDER_DETAIL_VIEW": "",
    "APP_PRODUCT_DETAIL_VIEW": "",
    "APP_REJECT_ORDER": "COMMON_ADMIN",
    "APP_ORDER_UPDATE": "",
    "APP_RF_CONFIG_UPDATE": "COMMON_ADMIN",
    "APP_PARTIAL_ORDER_REJECTION_CONFIG_UPDATE": "COMMON_ADMIN",
    "APP_STOREFULFILLMENT_ADMIN": "STOREFULFILLMENT_ADMIN"
} as any 