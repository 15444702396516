import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_note = _resolveComponent("ion-note")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createBlock(_component_ion_content, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_list, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_list_header, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.translate("Inventory computation")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Quantity on hand")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_note, { slot: "end" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getProductStock(_ctx.item.productId).quantityOnHandTotal ?? '0'), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Safety stock")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_note, { slot: "end" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getInventoryInformation(_ctx.item.productId).minimumStock ?? '0'), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Order reservations")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_note, { slot: "end" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getInventoryInformation(_ctx.item.productId).reservedQuantity ?? '0'), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, { lines: "none" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Online ATP")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_badge, {
                slot: "end",
                color: "success"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getInventoryInformation(_ctx.item.productId).onlineAtp ?? '0'), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}