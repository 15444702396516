import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withKeys as _withKeys, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "ion-text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_note = _resolveComponent("ion-note")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_buttons, { slot: "start" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, { onClick: _ctx.closeModal }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, { icon: _ctx.close }, null, 8, ["icon"])
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_title, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate("Other stores inventory")), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, { class: "ion-padding" }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_searchbar, {
          modelValue: _ctx.queryString,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.queryString) = $event)),
          onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => {_ctx.queryString = $event.target.value; _ctx.searchFacilities()}, ["enter"]))
        }, null, 8, ["modelValue"]),
        (_ctx.storesInventory.length)
          ? (_openBlock(), _createBlock(_component_ion_list, { key: 0 }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.storesInventory, (storeInventory) => {
                  return (_openBlock(), _createBlock(_component_ion_item, {
                    key: storeInventory.facilityName
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(storeInventory.facilityName), 1)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_ion_note, { slot: "end" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate('ATP', { count: storeInventory.stock})), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            }))
          : (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("p", null, _toDisplayString(_ctx.translate("No inventory details found")), 1)
            ]))
      ]),
      _: 1
    })
  ], 64))
}