import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ion-text-wrap" }
const _hoisted_2 = {
  key: 0,
  slot: "end"
}
const _hoisted_3 = {
  key: 1,
  class: "atp-info"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxpShopifyImg = _resolveComponent("DxpShopifyImg")!
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_note = _resolveComponent("ion-note")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_openBlock(), _createBlock(_component_ion_item, { lines: "none" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_thumbnail, { slot: "start" }, {
        default: _withCtx(() => [
          _createVNode(_component_DxpShopifyImg, {
            src: _ctx.getProduct(_ctx.item.productId).mainImageUrl,
            size: "small"
          }, null, 8, ["src"])
        ]),
        _: 1
      }),
      _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
        default: _withCtx(() => [
          _createElementVNode("h2", null, _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.getProduct(_ctx.item.productId)) ? _ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.getProduct(_ctx.item.productId)) : _ctx.getProduct(_ctx.item.productId).productName), 1),
          _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.secondaryId, _ctx.getProduct(_ctx.item.productId))), 1)
        ]),
        _: 1
      }),
      (!_ctx.isShipToStoreOrder)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_ctx.isFetchingStock)
              ? (_openBlock(), _createBlock(_component_ion_spinner, {
                  key: 0,
                  color: "medium",
                  name: "crescent"
                }))
              : (_ctx.getProductStock(_ctx.item.productId).quantityOnHandTotal >= 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_component_ion_note, { slot: "end" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("on hand", { count: _ctx.getProductStock(_ctx.item.productId).quantityOnHandTotal ?? '0' })), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_button, {
                      fill: "clear",
                      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.openInventoryDetailPopover($event)), ["stop"]))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, {
                          slot: "icon-only",
                          icon: _ctx.informationCircleOutline,
                          color: "medium"
                        }, null, 8, ["icon"])
                      ]),
                      _: 1
                    })
                  ]))
                : (_openBlock(), _createBlock(_component_ion_button, {
                    key: 2,
                    fill: "clear",
                    onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.fetchProductStock(_ctx.item.productId)), ["stop"]))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        color: "medium",
                        slot: "icon-only",
                        icon: _ctx.cubeOutline
                      }, null, 8, ["icon"])
                    ]),
                    _: 1
                  }))
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}